import React from 'react';
import { graphql } from 'gatsby';
import MainLayout from '../../components/MainLayout';
import useInstagramLazyLoader from '../../hooks/useInstagramLazyLoader';
import ArticleHead from '../../components/ArticleMarkup/ArticleHead';
import parseBlocks from '../../utilities/parseBlocks';
import * as style from '../Article/Article.module.scss';
import useOnNewActiveLanguage from '../../hooks/useOnNewActiveLanguage';
import ArticleSEO from '../../components/SEO/ArticleSEO';

const Page = (props) => {
  const { data, pageContext } = props;
  const { wpPage, topMenu, bottomMenu, footer, socialMenu, site } = data;
  const {
    title,
    Lead,
    featuredImage,
    blocks,
    language,
    seo,
    AdvancedSEOSettings,
    slug,
    translationsAxios,
  } = wpPage;
  const { siteMetadata } = site;
  const { siteUrl } = siteMetadata;
  const advancedSeo = AdvancedSEOSettings.advancedSeoSettings;

  useInstagramLazyLoader();

  const setNewActiveLanguage = useOnNewActiveLanguage(
    pageContext?.locale,
    translationsAxios
  );

  return (
    <MainLayout
      setNewActiveLanguage={setNewActiveLanguage}
      topMenu={topMenu}
      bottomMenu={bottomMenu}
      footer={footer}
      socialMenu={socialMenu}
      hasNoMarginBelow
    >
      <ArticleSEO
        seo={seo}
        advancedSeo={advancedSeo}
        postLocale={wpPage?.language?.locale}
        postUrl={slug}
        siteUrl={siteUrl}
        translations={translationsAxios}
      />
      <div className={style.articleContents}>
        <ArticleHead
          title={title}
          lead={Lead?.lead}
          imgData={featuredImage?.node?.imgixImage?.gatsbyImageData}
          imgAlt={featuredImage?.node?.altText || featuredImage?.node?.slug}
          imgHoverTitle={
            featuredImage?.node?.title || featuredImage?.node?.slug
          }
        />
        <div className={style.articleBlocks}>
          {parseBlocks(blocks, language?.locale)}
        </div>
      </div>
    </MainLayout>
  );
};

export default Page;

export const pageQuery = graphql`
  query (
    $id: String!
    $topMenuId: String!
    $bottomMenuId: String!
    $footerId: String!
    $socialMenuId: String!
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    topMenu: wpMenu(id: { eq: $topMenuId }) {
      ...topMenuFields
    }
    bottomMenu: wpMenu(id: { eq: $bottomMenuId }) {
      ...otherMenuFields
    }
    footer: wpMenu(id: { eq: $footerId }) {
      ...otherMenuFields
    }
    socialMenu: wpMenu(id: { eq: $socialMenuId }) {
      ...otherMenuFields
    }
    wpPage(id: { eq: $id }) {
      id
      databaseId
      title
      uri
      slug
      featuredImage {
        node {
          altText
          title
          slug
          imgixImage {
            gatsbyImageData(
              placeholder: BLURRED
              sizes: "(max-width: 768px) 420px, 640px"
              width: 640
              height: 640
              imgixParams: { fit: "crop", crop: "faces,entropy" }
              layout: FULL_WIDTH
            )
          }
        }
      }
      author {
        node {
          name
          slug
          hq_avatar {
            hqAvatar {
              id
              imgixImage {
                gatsbyImageData(
                  srcSetMaxWidth: 256
                  placeholder: BLURRED
                  sizes: "42px"
                  layout: FULL_WIDTH
                )
              }
            }
          }
          description
          multilingual_bio {
            multilingualBio {
              bioText
              locale
            }
          }
        }
      }
      dateGmt
      translationsAxios {
        slug
        language {
          locale
        }
      }
      language {
        locale
      }
      blocks {
        order
        saveContent
        __typename
        ... on WpCoreVideoBlock {
          attributes {
            ... on WpCoreVideoBlockAttributes {
              src
              poster
              muted
              loop
              controls
              autoplay
              playsInline
            }
          }
        }
        ... on WpAcfProductReviewBlock {
          productReview {
            individualProductReviewAnchorLinkId
            number
            productName
            productNameHeadingTag
            productReviewText
            productFeatures {
              featureName
              featureRating
              fieldGroupName
            }
            fieldGroupName
            pros {
              prosTitle
              prosList {
                proIcon
                proText
              }
            }
            cons {
              consTitle
              consList {
                conText
                conIcon
              }
            }
            button {
              fieldGroupName
              shopButton
              shopLink
            }
            image {
              altText
              sourceUrl
              imgixImage {
                gatsbyImageData(
                  placeholder: BLURRED
                  sizes: "(max-width: 768px) 420px, 640px"
                  layout: FULL_WIDTH
                  imgixParams: { auto: "format", q: 70, usm: 15 }
                )
              }
            }
          }
        }
        ... on WpAcfNumberedHeadingBlock {
          numberedHeading {
            numberedHeading
            numberedHeadingTitle
            numberedHeadingTitleTag
            fieldGroupName
            awardsList {
              awardDescriptionPreselection
              awardName
              fieldGroupName
            }
            numberedHeadingAnchorLinkId
          }
        }
        ... on WpAcfCallOutBulletListBlock {
          CallOutBulletList {
            fieldGroupName
            iconBulletPointList {
              fieldGroupName
              iconBulletPointListHeading
              iconBulletPointListIcon
              iconBulletPointListTextMultiParagraphSection {
                iconBulletPointListTextParagraph
              }
            }
          }
        }
        ... on WpAcfCallOutHorizontalBlock {
          CallOutHorizontal {
            callOutBoxHorizontal {
              callOutBoxHorizontalHeading
              callOutBoxHorizontalIcon
              callOutBoxHorizontalText
              fieldGroupName
            }
          }
        }
        ... on WpCoreListBlock {
          saveContent
        }
        ... on WpAcfSimpleIconListBlock {
          dynamicContent
          originalContent
          SimpleIconList {
            simpleIconLists {
              simleIconListText
              simpleIconListIcon
            }
          }
        }
        ... on WpAcfAccordionBlock {
          dynamicContent
          originalContent
          Accordion {
            accordion {
              accordionTitle
              accordionTextMultiEntry {
                accordionText
                accordionTextAdvancedTocLink {
                  target
                  title
                  url
                }
              }
            }
          }
        }
        ... on WpAcfCallOutConversationBlock {
          dynamicContent
          CallOutConversation {
            conversation {
              conversationAnswer
              conversationQuestion
            }
          }
        }
        ... on WpAcfCustomImageBlock {
          dynamicContent
          originalContent
          CustomImage {
            customImageAttributionText
            customImageTitleAndAltText {
              customImageTitleTag
              customImageAltText
            }
            fieldGroupName
            customImageBlock {
              altText
              title
              slug
              mimeType
              sourceUrl
              imgixImage {
                gatsbyImageData(
                  placeholder: BLURRED
                  sizes: "(max-width: 768px) 420px, 640px"
                  layout: FULL_WIDTH
                  imgixParams: { auto: "format", q: 70, usm: 15 }
                )
              }
            }
            customImageWebsiteAttributionOption {
              customImageWebsiteAttributionTextAndLink {
                target
                title
                url
              }
              customImageWebsiteAttributionTextAndLinkMakeNoFollow
            }
          }
        }
        ... on WpAcfCustomButtonBlock {
          dynamicContent
          originalContent
          customButton {
            customButton {
              customButtonLinkMakeNoFollow
              customButtonStyle
              customButtonTextAndLink {
                target
                title
                url
              }
            }
          }
        }
        ... on WpAcfNumberedHeadingWithImageBlock {
          dynamicContent
          originalContent
          numberedHeading {
            numberedHeading
            numberedHeadingTitle
            numberedHeadingTitleTag
            fieldGroupName
            awardsList {
              awardDescriptionPreselection
              awardName
              fieldGroupName
            }
            numberedHeadingAnchorLinkId
          }
          CustomImage {
            customImageAttributionText
            fieldGroupName
            customImageBlock {
              altText
              title
              slug
              imgixImage {
                gatsbyImageData(
                  placeholder: BLURRED
                  sizes: "(max-width: 768px) 420px, 640px"
                  layout: FULL_WIDTH
                  imgixParams: { auto: "format", q: 70, usm: 15 }
                )
              }
            }
            customImageWebsiteAttributionOption {
              customImageWebsiteAttributionTextAndLink {
                target
                title
                url
              }
              customImageWebsiteAttributionTextAndLinkMakeNoFollow
            }
          }
        }
        ... on WpAcfImageQuoteBlock {
          dynamicContent
          originalContent
          ImageQuote {
            imageQuote {
              imageQuoteName {
                imageQuoteNameQuoteText
                imageQuoteNameText
                imageQuoteNameTitle
                imageQuoteNameTitleLinkUrl
                imageQuoteNameTitleLinkUrlMakeNoFollow
                imageQuoteNameTitleLinkUrlOpenNewTab
                imageQuoteNameImage {
                  imgixImage {
                    gatsbyImageData(
                      placeholder: BLURRED
                      sizes: "(max-width: 768px) 420px, 640px"
                      layout: FULL_WIDTH
                      imgixParams: { auto: "format", q: 70, usm: 15 }
                    )
                  }
                }
              }
            }
          }
        }
        ... on WpAcfPeopleListBlock {
          dynamicContent
          originalContent
          PeopleList {
            peopleListEnableSlider
            peopleList {
              peopleListGroup {
                peopleListBioText
                peopleListHyperlinks {
                  peopleListHyperlinkTextAndLink {
                    target
                    title
                    url
                  }
                  peopleListHyperlinkTextAndLinkMakeNoFollow
                }
                peopleListName
                peopleListSubheadingText
                peopleListTitle
                peopleListImage {
                  imgixImage {
                    gatsbyImageData(
                      placeholder: BLURRED
                      sizes: "(max-width: 768px) 210px, 320px"
                      layout: FULL_WIDTH
                      imgixParams: { auto: "format", q: 70, usm: 15 }
                    )
                  }
                }
              }
            }
          }
        }
        ... on WpAcfTableOfContentsBlock {
          dynamicContent
          originalContent
          TableOfContents {
            fieldGroupName
            tableOfContents {
              tocAnchorLink
              tocText
            }
          }
        }
        ... on WpCoreHeadingBlock {
          dynamicContent
          originalContent
          attributes {
            ... on WpCoreHeadingBlockAttributes {
              anchor
              textAlign
            }
          }
        }
        ... on WpCoreHtmlBlock {
          saveContent
        }
        ... on WpAcfCustomVideoBlock {
          customVideo {
            customVideoUrl
            customVideoAllowFullscreen
            customVideoAutoplay
            customVideoKeyboardControls
            customVideoHideYtLogo
            customVideoControls
            customVideoLoop
            customVideoMuted
          }
        }
        ... on WpCoreTableBlock {
          attributes {
            ... on WpCoreTableBlockAttributes {
              align
              anchor
              body {
                cells {
                  align
                  content
                  scope
                  tag
                }
              }
            }
          }
        }
      }
      seo {
        canonical
        title
        metaDesc
        focuskw
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphPublisher
        opengraphAuthor
        opengraphDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
        }
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
        }
        breadcrumbs {
          url
          text
        }
        cornerstone
        schema {
          pageType
          articleType
          raw
        }
        readingTime
      }
      AdvancedSEOSettings {
        advancedSeoSettings {
          customCanonical
          noindexPost
        }
      }
    }
  }
`;
